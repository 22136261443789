const localesMap: Record<string, string> = {
  en: "English",
  ru: "Русский",
  de: "Deutsch",
  fr: "Français",
  es: "Español",
  it: "Italiano",
  pt: "Português",
  pl: "Polski",
  ar: "العربية",
  tr: "Türkçe",
  th: "ไทย",
};

const i18n = {
  supportedLngs: Object.keys(localesMap),
  fallbackLng: "en",
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: "translations",
  // Disabling suspense is recommended
  react: { useSuspense: false }
};


export default i18n;

export function getLocale(urlOrPathname: string, returnFallback = false): string|null {
  const path = urlOrPathname.startsWith('http') ? new URL(urlOrPathname).pathname : urlOrPathname;
  const pathSegments = path.split("/");

  // Assuming the language code is the second segment in the URL path (/en/some/path)
  if (!i18n.supportedLngs.includes(pathSegments[1] ?? "")) {
      return returnFallback ? i18n.fallbackLng : null;
  }

  return pathSegments[1];
}

export function getLocaleName(locale: string): string {
  if (!(locale in localesMap)) {
    console.error(`Locale name for ${locale} is not defined in locales map`);
  }
  return localesMap[locale] ?? locale;
}